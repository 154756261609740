import React from "react";
import { Item, ItemProps } from "react-photoswipe-gallery";

const SrcGalleryItem =(props: Omit<ItemProps, 'children'>& { children: JSX.Element, src?:string | null})=>{
  const {src, ...rest} = props;
  return <Item
    original={src??""}
    {...rest}>
    {({ref, open})=><div ref={ref as any} onClick={open} className="cursor-pointer">
      {rest.children}
    </div>}
  </Item>
}
export default SrcGalleryItem;