import { Box, Chip, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import ReactMarkdown from "react-markdown"
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import rehypeRaw from 'rehype-raw'
import React, { FunctionComponent, PropsWithChildren, ReactNode, useCallback, useRef } from "react";
import CombinedLink from "./CombinedLink";
import { SpecialComponents } from "react-markdown/lib/ast-to-react";
import { NormalComponents, ReactMarkdownProps } from "react-markdown/lib/complex-types";
import SrcGalleryItem from "./gallery/SrcGalleryItem";
import { throttle } from "lodash";
import { toNextImageSrc } from "@/lib/media";
import dynamic from "next/dynamic";
import { SharePanel } from "./ShareButton";
import { isBrowser } from "react-device-detect";
import { useTextSelection } from 'use-text-selection';
import clsx from 'clsx';
declare type PopoverProps = {
  target?: HTMLElement;
  mount?: HTMLElement;
  render: FunctionComponent<ReturnType<typeof useTextSelection>>;
};
const Popover = dynamic<PropsWithChildren<PopoverProps>>(()=>import("react-text-selection-popover").then(it=>it.Popover),{
  ssr:false
});

const hashtagSimplify = (str: string) => str.replace(/(<.*?>)|(&.*?;)/gm, "").replace(/\s\s+/g, ' ').trim();
const chineseSlugify = (str: string) => str.replace(/[^(\u4E00-\u9FFF0-9a-zA-Z-)]/g, "-");
const useStyles = makeStyles()(theme => ({
  contentTypography: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    lineHeight: "2",
    letterSpacing: "1px",
    fontWeight: 300,
    textAlign:"justify",
    overflowWrap: "break-word",
  },
  customIframeaspectRatio: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }
}));

const CustReactMarkdown = (props: ReactMarkdownOptions & {
  customComponents?: Partial<
    (
      Omit<
        NormalComponents,
        keyof SpecialComponents
      > &
      SpecialComponents
    ) | { [key: string]: (props: PropsWithChildren<ReactMarkdownProps>) => ReactNode }
  > | undefined, enableGalleryImage?: boolean, enableHighlightShare?:boolean, pageShareModel?:{title:string, description:string, url:string}
}) => {
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const { customComponents, enableGalleryImage, enableHighlightShare, pageShareModel, ...markdownProps } = props;
  const breakpoints = theme.breakpoints.values;
  const [windowHeight, setWindowHeight] = React.useState(0);
  const [imageLoaded, setImageLoaded] = React.useState<{ [key: string]: boolean }>({});
  const [loadedImageSize, setLoadedImageSize] = React.useState<{ [key: string]: { width: number, height: number } }>({});
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const lgUp = useMediaQuery(theme.breakpoints.down('lg'));


  const handleResize = useCallback((event: Event) => {
    throttle(() => {
      setWindowHeight(window.innerHeight);
    }, 500)();
  }, []);

  React.useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);
  const ref = useRef<HTMLDivElement | null>(null);
  return (<>
    <div ref={ref} className={clsx("dark:text-dark-content-100 ",enableHighlightShare?"selection:bg-primary-500 selection:text-black  ":"")}>
      <ReactMarkdown
        className={clsx(props.className, 'ck-content ')}
        rehypePlugins={[rehypeRaw]}
        components={{
          figcaption: ({ node, children, ...props }) => <figcaption {...props} className="sm:w-full sm:!mx-auto md:w-full md:!mx-auto lg:w-3/5 text-gray-500 text-sm font-thin py-2 px-2 md:px-4">{children}</figcaption>,
          figure: ({ node, children, ...props }) => <figure {...props} style={{ ...props.style, width: smDown ? "100%" : props?.style?.width }} className="mx-0 my-4 sm:mx-auto w-full sm:w-auto">{children}</figure>,
          img: ({ node, ...props }) =>
            <Box m={0} className={cx(imageLoaded[props.src ?? ""] ? "" : "min-h-[150px]", "sm:w-full sm:!mx-auto md:w-full md:!mx-auto lg:w-3/5 relative bg-gray-200/20 bg-[url('/no_photo.png')] bg-contain bg-no-repeat bg-center")}
              key={props.key}>
              {enableGalleryImage == true ?
                <SrcGalleryItem src={toNextImageSrc(props.src??"")} width={loadedImageSize[props.src ?? ""]?.width ?? '100vw'} height={loadedImageSize[props.src ?? ""]?.height?? windowHeight} title={props.alt}>
                  {/* eslint-disable-next-line @next/next/no-img-element*/}
                  <img src={toNextImageSrc(props.src??"")} alt={props.alt} className="mx-auto" onLoad={(ev) => {
                    if (!imageLoaded[props.src ?? ""])
                      setImageLoaded(curr => ({ ...curr, [props.src ?? ""]: true }));
                    if (!loadedImageSize[props.src ?? ""])
                      setLoadedImageSize(curr => ({ ...curr, [props.src ?? ""]: { width: (ev.target as HTMLImageElement).naturalWidth, height: (ev.target as HTMLImageElement).naturalHeight } }));
                  }} />
                </SrcGalleryItem> :
                <>
                  {/* eslint-disable-next-line @next/next/no-img-element*/}
                  <img src={toNextImageSrc(props.src??"")} alt={props.alt} className="mx-auto"   onLoad={(ev) => {
                    if (!imageLoaded[props.src ?? ""])
                      setImageLoaded(curr => ({ ...curr, [props.src ?? ""]: true }));
                    if (!loadedImageSize[props.src ?? ""])
                      setLoadedImageSize(curr => ({ ...curr, [props.src ?? ""]: { width: (ev.target as HTMLImageElement).naturalWidth, height: (ev.target as HTMLImageElement).naturalHeight } }));
                  }} />
                </>
              }
              {/* <NextImage
            // loader={(props) => props.src}
            layout="responsive"
            src={props.src ?? ""}
            alt={props.alt}
            objectFit="contain"
            objectPosition="center"
            width={4} height={3}
          /> */}
            </Box>,
          // img: ({ node, ...props }) =>
          //   <Box position="relative" textAlign="center"
          //   key={props.key}>
          //     <NextImage
          //       loader={(props) => props.src}
          //       layout="responsive"
          //       src={props.src ?? ""}
          //       alt={props.alt}
          //       objectFit="cover"
          //       width={16} height={9}
          //     />
          //     <Box position="absolute" bottom={0} left={0} width="100%">z
          //       <Typography variant="caption" color="inherit" style={{
          //            paddingTop:theme.spacing(2), paddingBottom:theme.spacing(2),
          //           paddingLeft:theme.spacing(4), paddingRight: theme.spacing(4), backgroundColor:theme.palette.primary.main, color:theme.palette.primary.contrastText, opacity: 0.8
          //         }}>{props.alt}</Typography>
          //     </Box>
          //   </Box>,
          blockquote: ({ node, color, ...props }) =>
            <div className="m-2 p-2 pl-8 border-l-4 border-l-gray-300"
              key={props.key}>
              <span {...props}/>
              {/* <Typography variant="body1" {...props} /> */}
            </div>,
          p: ({ node, color, children, ...props }) => {
            const line = (children as string[])?.[0];
            const matchHashTag = line.match && line.match(/^[ \n\r]*#.+#.*$/m)

            if (matchHashTag)
              return <div  {...props} style={{ letterSpacing: "1px" }} >
                {line.split("#").map(it => it.trim()).filter(it => it.length > 0).map((it, idx) =>
                  <CombinedLink href={"/tag/" + chineseSlugify(hashtagSimplify(it.toLocaleLowerCase()))} scroll underline="none" color="inherit" key={"hashTag_" + idx}>
                    <Chip className="dark:hover:bg-dark-content-200 dark:text-dark-content-100 dark:bg-dark-content-300" label={`#${hashtagSimplify(it)}`} style={{ margin: theme.spacing(1) }} onClick={() => { }} />
                  </CombinedLink>
                )}
              </div>
            else{            
              delete props.style?.background;
              delete props.style?.backgroundColor;
              return <Typography variant="body1"  {...props} className={clsx("", classes.contentTypography)} component="div">
                {children}
              </Typography>
            }
          },
          iframe: ({ ...props }) => {
            const fburl = /https:\/\/www.facebook.com\//g;
            const fbResult = props.src?.match(fburl);
            if (fbResult) {
              props.height = "100%";
              props.width = lgUp ? "100%" : "100%"; // PC = 50%, phone = 100%
              return <Box
                sx={{
                  width: "100%",
                  paddingTop: lgUp ? "56.25%" : "100%", // PC = 56.25%, phone = 100%
                  position: "relative",
                  marginTop: "2.5rem",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: lgUp ? "0" : 0, // PC = 0, phone = 0;
                  }}
                >
                  {/* <YouTube
              videoId={firstArticle?.video?.videoId ?? ""}
              opts={{ height: "100%", width: "100%" }}
              containerClassName={classes.youtubeVideo}
            /> */}
                  <iframe height={props.height} width={props.width} src={props.src} allowFullScreen={true} allow={props.allow} style={{ ...props.style, margin: lgUp ? "auto" : "0" /*, aspectRatio:"1/1" */ }} ></iframe>
                </Box>
              </Box>
            // return <iframe height={props.height} width={props.width} src={props.src} allowFullScreen={true} allow={props.allow} style={{...props.style, aspectRatio:"1/1" }} ></iframe>
            } else {
              return <iframe height={props.height} width={"100%"} src={props.src} allowFullScreen={true} allow={props.allow} style={{ ...props.style }} ></iframe>
            }

          // return <iframe height={"100%"} width={"100%"} src={props.src} allow={props.allow} style={props.style} ></iframe>
          },
          a: ({ node, children, ...props }) => <a {...props} className="text-blue-600 hover:text-blue-800 dark:text-dark-hyper-blue transition">{children}</a>,
          h1: ({ node, color, ...props }) => <h2 {...props} className={cx("text-4xl", classes.contentTypography)} />,
          h2: ({ node, color, ...props }) => <h3 {...props} className={cx("text-3xl", classes.contentTypography)} />,
          h3: ({ node, color, ...props }) => <h4 {...props} className={cx("text-2xl", classes.contentTypography)} />,
          h4: ({ node, color, ...props }) => <h5 {...props} className={cx("text-xl", classes.contentTypography)} />,
          ul: ({ node, color, ...props }) => <ul {...props} className={cx("list-disc", props.className)} />,
          ol: ({ node, color, ...props }) => <ul {...props} className={cx("list-decimal", props.className)} />,
          span:({ node, color, ...props }) => {
            delete props.style?.background;
            delete props.style?.backgroundColor;
            return <span {...props} />
          },
          ...customComponents
          // h5: ({ node, color, ref, ...props }) => <Typography variant="h5" {...props} className={classes.contentTypography} />,
          // h6: ({ node, color, ref, ...props }) => <Typography variant="h6" {...props} className={classes.contentTypography} />,

        }}
        transformImageUri={uri =>
          uri.startsWith("http") ? uri : `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${uri}`
        }
        {...markdownProps}
      />
    </div>
    {enableHighlightShare && pageShareModel &&
    <Popover
      target={ref?.current??undefined}
      render={
        ({ clientRect, isCollapsed, textContent }) => {
          if (clientRect == null || isCollapsed) return null
          
          return <div className={"absolute w-auto -translate-x-1/2"} style={{
            left:`${Math.max(80, Math.min(document.body.clientWidth-80, clientRect.left + clientRect.width / 2))}px`, top:`${clientRect.top + (document.documentElement.scrollTop || document.body.scrollTop) - (isBrowser?40:80)}px`
          }}>
            <SharePanel model={{title:pageShareModel.title, description:pageShareModel.description, prefix:`"${textContent}"\r\n-----\r\n`}} url={pageShareModel.url} 
              searchParams={{utm_source:"web", utm_medium:"cchlshare"}} />
          </div>
        }
      }
    />}
  </>);
}

export default CustReactMarkdown