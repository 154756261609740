import Head from "next/head"
import { useContext } from "react"
import { GlobalContext } from "../pages/_app"
import { getStrapiMedia, srcExtractor } from "../lib/media"
import { ComponentSharedSeo, UploadFile } from "../lib/remoteGraphQL"
import React from "react"
import ReactGA from "react-ga4";

export type SeoProps =
  | null
  | (ComponentSharedSeo & { article?: boolean; tags?: string[] });

const Seo = (props: { seo?: SeoProps, noPageViewGaEvent?: boolean, skipImage?:boolean}) => {
  const {seo,noPageViewGaEvent, skipImage} = props;
  const { defaultSeo, siteName } = useContext(GlobalContext)
  const seoWithDefaults = {
    ...defaultSeo,
    ...seo,
  }
  const fullSeo:ComponentSharedSeo & {article?: boolean, shareImageUrl?: string|undefined, tags?: string[]} = {
    ...seoWithDefaults,
    // Add title suffix
    metaTitle: seoWithDefaults.disableSiteNameInTitle==true?`${seoWithDefaults.metaTitle}`: `${seoWithDefaults.metaTitle} | ${siteName}`,
    ...(skipImage?{

    }:{
      // Get full image URL
      shareImageUrl: srcExtractor(getStrapiMedia(seoWithDefaults.shareImage ?? {} as UploadFile)),
    })
  }

  return (
    <Head>
      {fullSeo.metaTitle && (
        <>
          <title>{fullSeo.metaTitle}</title>
          <meta name="title" content={fullSeo.metaTitle} />
          <meta property="og:title" content={fullSeo.metaTitle} />
          <meta name="twitter:title" content={fullSeo.metaTitle} />
        </>
      )}
      {fullSeo.metaDescription && (
        <>
          <meta name="description" content={fullSeo.metaDescription} />
          <meta property="og:description" content={fullSeo.metaDescription} />
          <meta name="twitter:description" content={fullSeo.metaDescription} />
        </>
      )}
      {fullSeo.shareImageUrl && (
        <>
          <meta name="image" content={fullSeo.shareImageUrl} />
          <meta property="og:image" content={fullSeo.shareImageUrl} />
          <meta name="twitter:image" content={fullSeo.shareImageUrl} />
        </>
      )}
      {fullSeo.article && <>
        <meta property="og:type" content="article" />
      </>}
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  )
}

export default Seo
